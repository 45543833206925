<template>
  <div
    @dragover.prevent
    @drop.prevent="handleImageDrop($event)"
    @paste.prevent="handlePaste($event)"
  >
    <!-- error notifications -->
    <v-alert v-show="error" transition="fade-transition" color="error" dense>
      <v-row align="center">
        <v-col class="grow">
          <div class="d-flex align-center">
            <v-icon class="mr-5">mdi-alert-outline</v-icon>
            <p class="mb-0">
              {{ errorMessage }}
            </p>
          </div>
        </v-col>
        <v-col class="shrink">
          <v-btn title="close" icon>
            <v-icon @click="error = false"> mdi-close </v-icon>
          </v-btn>
        </v-col>
      </v-row>
    </v-alert>

    <v-alert
      v-show="warning"
      transition="fade-transition"
      color="orange darken-3"
      dense
    >
      <v-row align="center">
        <v-col class="grow">
          <div class="d-flex align-center">
            <v-icon class="mr-5">mdi-information-variant-circle-outline</v-icon>
            <p class="mb-0">
              {{ warningMessage }}
            </p>
          </div>
        </v-col>
        <v-col class="shrink">
          <v-btn title="close" icon>
            <v-icon @click="warning = false"> mdi-close </v-icon>
          </v-btn>
        </v-col>
      </v-row>
    </v-alert>

    <v-alert
      v-show="isTyping"
      transition="fade-transition"
      color="orange darken-3"
      dense
    >
      <v-row align="center">
        <v-col class="grow">
          <div class="d-flex align-center">
            <v-icon class="mr-5">mdi-information-variant-circle-outline</v-icon>
            <p class="mb-0">
              {{
                isTyping
                  ? "Click the save note button below this text field to create a new note."
                  : ""
              }}
            </p>
          </div>
        </v-col>
      </v-row>
    </v-alert>

    <!-- toolbars -->
    <div v-if="editor" class="tiptop-editor-toolbar rounded-lg rounded-b-0">
      <div v-if="isInTable" class="d-flex align-center">
        <v-btn title="Delete Table" small text @click="deleteTable">
          <v-icon class="text-subtitle-1">mdi-table-off</v-icon>
        </v-btn>

        <v-btn title="Insert Row Above" small text @click="addRowAbove">
          <v-icon class="text-subtitle-1">mdi-table-row-plus-before</v-icon>
        </v-btn>
        <v-btn title="Insert Row Below" small text @click="addRowBelow">
          <v-icon class="text-subtitle-1">mdi-table-row-plus-after</v-icon>
        </v-btn>

        <v-btn title="Insert Column Before" small text @click="addColumnBefore">
          <v-icon class="text-subtitle-1">mdi-table-column-plus-before</v-icon>
        </v-btn>
        <v-btn title="Insert Column After" small text @click="addColumnAfter">
          <v-icon class="text-subtitle-1">mdi-table-column-plus-after</v-icon>
        </v-btn>

        <v-btn title="Delete Row" small text @click="deleteRow">
          <v-icon class="text-subtitle-1">mdi-table-row-remove</v-icon>
        </v-btn>
        <v-btn title="Delete Column" small text @click="deleteColumn">
          <v-icon class="text-subtitle-1">mdi-table-column-remove</v-icon>
        </v-btn>

        <v-btn title="Toggle Header" small text @click="toggleHeader">
          <v-icon class="text-subtitle-1">mdi-table-edit</v-icon>
        </v-btn>

        <v-divider vertical class="mr-2"></v-divider>
      </div>

      <v-btn
        @click="editor.chain().focus().toggleBold().run()"
        :disabled="!editor.can().chain().focus().toggleBold().run()"
        :class="{ 'is-active': editor.isActive('bold') }"
        title="Bold"
        small
        text
      >
        <v-icon class="text-subtitle-1">mdi-format-bold</v-icon>
      </v-btn>

      <v-btn
        @click="editor.chain().focus().toggleItalic().run()"
        :disabled="!editor.can().chain().focus().toggleItalic().run()"
        :class="{ 'is-active': editor.isActive('italic') }"
        title="Italic"
        small
        text
      >
        <v-icon class="text-subtitle-1">mdi-format-italic</v-icon>
      </v-btn>

      <v-btn
        @click="editor.chain().focus().toggleUnderline().run()"
        :disabled="!editor.can().chain().focus().toggleUnderline().run()"
        :class="{ 'is-active': editor.isActive('underline') }"
        title="Underline"
        small
        text
      >
        <v-icon class="text-subtitle-1">mdi-format-underline</v-icon>
      </v-btn>

      <v-btn
        @click="editor.chain().focus().toggleStrike().run()"
        :disabled="!editor.can().chain().focus().toggleStrike().run()"
        :class="{ 'is-active': editor.isActive('strike') }"
        title="Strike"
        small
        text
      >
        <v-icon class="text-subtitle-1"
          >mdi-format-strikethrough-variant</v-icon
        >
      </v-btn>

      <v-btn
        @click="editor.chain().focus().toggleCode().run()"
        :disabled="!editor.can().chain().focus().toggleCode().run()"
        :class="{ 'is-active': editor.isActive('code') }"
        title="Code"
        small
        text
      >
        <v-icon class="text-subtitle-1">mdi-xml</v-icon>
      </v-btn>

      <v-btn
        @click="editor.chain().focus().unsetAllMarks().run()"
        title="Clear Marks"
        small
        text
      >
        <v-icon class="text-subtitle-1">mdi-eraser</v-icon>
      </v-btn>

      <v-btn
        @click="editor.chain().focus().clearNodes().run()"
        :class="{ 'is-active': editor.isActive('clearNodes') }"
        title="Clear Nodes"
        small
        text
      >
        <v-icon class="text-subtitle-1">mdi-broom</v-icon>
      </v-btn>

      <v-btn
        @click="editor.chain().focus().setParagraph().run()"
        :class="{ 'is-active': editor.isActive('paragraph') }"
        title="Paragraph"
        small
        text
      >
        <v-icon class="text-subtitle-1">mdi-format-paragraph</v-icon>
      </v-btn>

      <v-btn
        @click="editor.chain().focus().toggleHeading({ level: 1 }).run()"
        :class="{ 'is-active': editor.isActive('heading', { level: 1 }) }"
        title="Heading 1"
        small
        text
      >
        h1
      </v-btn>

      <v-btn
        @click="editor.chain().focus().toggleHeading({ level: 2 }).run()"
        :class="{ 'is-active': editor.isActive('heading', { level: 2 }) }"
        title="Heading 2"
        small
        text
      >
        h2
      </v-btn>

      <v-btn
        @click="editor.chain().focus().toggleHeading({ level: 3 }).run()"
        :class="{ 'is-active': editor.isActive('heading', { level: 3 }) }"
        title="Heading 3"
        small
        text
      >
        h3
      </v-btn>

      <v-btn
        @click="editor.chain().focus().toggleHeading({ level: 4 }).run()"
        :class="{ 'is-active': editor.isActive('heading', { level: 4 }) }"
        title="Heading 4"
        small
        text
      >
        h4
      </v-btn>

      <v-btn
        @click="editor.chain().focus().toggleHeading({ level: 5 }).run()"
        :class="{ 'is-active': editor.isActive('heading', { level: 5 }) }"
        title="Heading 5"
        small
        text
      >
        h5
      </v-btn>

      <v-btn
        @click="editor.chain().focus().toggleHeading({ level: 6 }).run()"
        :class="{ 'is-active': editor.isActive('heading', { level: 6 }) }"
        title="Heading 6"
        small
        text
      >
        h6
      </v-btn>

      <v-btn
        @click="editor.chain().focus().toggleBulletList().run()"
        :class="{ 'is-active': editor.isActive('bulletList') }"
        title="Bullet List"
        text
        small
      >
        <v-icon class="text-subtitle-1">mdi-format-list-bulleted</v-icon>
      </v-btn>

      <v-btn
        @click="editor.chain().focus().toggleOrderedList().run()"
        :class="{ 'is-active': editor.isActive('orderedList') }"
        title="Ordered List"
        text
        small
      >
        <v-icon class="text-subtitle-1">mdi-format-list-numbered-rtl</v-icon>
      </v-btn>

      <v-btn
        @click="editor.chain().focus().toggleCodeBlock().run()"
        :class="{ 'is-active': editor.isActive('codeBlock') }"
        title="Code Block"
        text
        small
      >
        <v-icon class="text-subtitle-1">mdi-code-tags</v-icon>
      </v-btn>

      <v-btn
        @click="editor.chain().focus().toggleBlockquote().run()"
        :class="{ 'is-active': editor.isActive('blockquote') }"
        title="Blockquote"
        text
        small
      >
        <v-icon class="text-subtitle-1">mdi-format-quote-open</v-icon>
      </v-btn>

      <v-btn
        @click="editor.chain().focus().setHorizontalRule().run()"
        title="Horizontal Rule"
        small
        text
      >
        <v-icon class="text-subtitle-1">mdi-minus</v-icon>
      </v-btn>

      <v-btn title="Insert Table" small text @click="insertTable">
        <v-icon class="text-subtitle-1">mdi-table-plus</v-icon>
      </v-btn>

      <v-btn
        @click="editor.chain().focus().setHardBreak().run()"
        title="Hard Break"
        small
        text
      >
        <v-icon class="text-subtitle-1">mdi-arrow-down-bold</v-icon>
      </v-btn>

      <v-btn
        @click="editor.chain().focus().undo().run()"
        :disabled="!editor.can().chain().focus().undo().run()"
        title="Undo"
        small
        text
      >
        <v-icon class="text-subtitle-1">mdi-undo</v-icon>
      </v-btn>

      <v-btn
        @click="editor.chain().focus().redo().run()"
        :disabled="!editor.can().chain().focus().redo().run()"
        title="Redo"
        small
        text
      >
        <v-icon class="text-subtitle-1">mdi-redo</v-icon>
      </v-btn>
    </div>

    <!-- editor -->
    <EditorContent
      @update="content = $event"
      class="tiptop-editor-content bordered rounded-lg rounded-t-0 pa-2"
      :editor="editor"
      ref="editorContent"
    />

    <!-- action -->
    <div class="d-flex justify-end mt-2">
      <!-- save btn -->
      <v-btn @click="handle_save" color="teal" small text>
        <v-icon class="mr-2">mdi-content-save-outline</v-icon>
        Save Note
      </v-btn>

      <!-- cancel btn -->
      <v-btn
        v-if="is_edit"
        @click="
          editor.commands.setContent('');
          is_edit = false;
          temp_note_id = null;
          warning = false;
          $emit('handleCancelEdit');
        "
        color="red"
        small
        text
      >
        <v-icon class="mr-2">mdi-close-circle-outline</v-icon>
        Cancel
      </v-btn>
    </div>

    <!-- image preview dialog -->
    <v-dialog v-model="dialog" fullscreen>
      <v-toolbar class="sticky-toolbar orange darken-3" flat>
        <v-toolbar-title>
          <v-icon>mdi-image</v-icon> Image Preview
        </v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn icon @click="dialog = false" title="Close">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-toolbar>

      <v-card>
        <v-card-text>
          <v-img :src="selectedImage" aspect-ratio="1" />
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import API from "@/plugins/API";
import { Editor, EditorContent } from "@tiptap/vue-2";
import StarterKit from "@tiptap/starter-kit";
import Placeholder from "@tiptap/extension-placeholder";
import Image from "@tiptap/extension-image";
import Underline from "@tiptap/extension-underline";
import Table from "@tiptap/extension-table";
import TableRow from "@tiptap/extension-table-row";
import TableCell from "@tiptap/extension-table-cell";
import TableHeader from "@tiptap/extension-table-header";

export default {
  props: ["id", "user_id", "type", "value_to_edit"],

  components: {
    EditorContent,
  },

  data: () => ({
    error: false,
    errorMessage: "",
    warning: false,
    warningMessage: "",
    is_edit: false,
    temp_note_id: null,

    content: "",
    editor: null,

    dialog: false,
    selectedImage: "",

    isTyping: false,
  }),

  methods: {
    async handle_save() {
      try {
        let response = null;

        if (
          this.type === "account_note" ||
          this.type === "fund_mort_note" ||
          this.type === "file_note"
        ) {
          if (!this.content) {
            this.warning = true;
            this.warningMessage = "Please fill in the field to save the note.";
            return;
          }

          if (this.type === "file_note") {
            this.$emit("handleSaveNote", {
              content: this.content,
              is_edit: this.is_edit,
            });
          } else {
            this.$emit("handleSaveNote", this.content, this.is_edit);
          }

          this.is_edit = false;
          this.temp_note_id = null;

          this.warning = false;
          this.warningMessage = "";

          // Clear the editor content

          this.content = "";
          this.editor.commands.clearContent();
          return;
        }

        if (this.is_edit) {
          response = await API().patch("api/internal-admin/note/update", {
            note_id: this.temp_note_id,
            content: this.content,
          });
        } else {
          response = await API().post("api/internal-admin/note/create", {
            id: this.id,
            user_id: this.user_id,
            content: this.content,
            type: this.type,
          });
        }

        if (response.status === 201) {
          console.log("Response Message: ", response.data.message);

          this.$emit("handleSaveNote", response.data.note, this.is_edit);

          this.warning = false;
          this.warningMessage = "";
        }

        if (response.status === 200) {
          this.warning = true;
          this.warningMessage = "Please fill in the field to save the note.";
        }

        this.is_edit = false;
        this.temp_note_id = null;

        // Clear the editor content
        this.content = "";
        this.editor.commands.clearContent();
      } catch (error) {
        console.log("Error: ", error);
        this.error = true;
        this.errorMessage = `Error ${error.response.status}: ${error.response.data.message}`;
      }
    },

    // image methods
    handleImageDrop(event) {
      event.preventDefault();
      event.stopPropagation();

      const files = event.dataTransfer.files;
      if (files.length > 0) {
        const file = files[0];
        this.insertImage(file);
      }
    },

    handlePaste(event) {
      const clipboardData = event.clipboardData;
      if (clipboardData) {
        const items = clipboardData.items;

        // If multiple items are being pasted, ignore images
        if (items.length > 1) {
          for (let i = 0; i < items.length; i++) {
            if (
              items[i].kind === "file" &&
              items[i].type.startsWith("image/")
            ) {
              event.preventDefault();
              break;
            }
          }
        } else {
          // If only one item is being pasted, handle it as before
          for (let i = 0; i < items.length; i++) {
            if (items[i].kind === "file") {
              const file = items[i].getAsFile();
              this.insertImage(file);
              break;
            }
          }
        }
      }
    },

    insertImage(file) {
      if (
        file &&
        ["image/png", "image/jpg", "image/jpeg"].includes(file.type)
      ) {
        const reader = new FileReader();
        reader.onload = (event) => {
          const imageUrl = event.target.result;
          this.editor.chain().focus().setImage({ src: imageUrl }).run();
        };
        reader.readAsDataURL(file);
      } else {
        this.error = true;
        this.errorMessage =
          "Invalid file type. Only PNG and JPG images are allowed.";
        console.log("Invalid file type. Only PNG and JPG images are allowed.");
      }
    },

    setContentToEdit(note) {
      this.editor.commands.setContent(note.content);
      this.is_edit = true;
      this.warning = true;
      this.warningMessage = "You are currently editing.";
      this.temp_note_id = note.id;
    },

    // image preview dialog
    onImageClick(event) {
      const target = event.target;
      if (target.tagName === "IMG") {
        this.selectedImage = target.src;
        this.dialog = true;
      }
    },

    // table methods
    insertTable() {
      this.editor.chain().focus().insertTable().run();
    },

    addRowAbove() {
      this.editor.chain().focus().addRowBefore().run();
    },

    addRowBelow() {
      this.editor.chain().focus().addRowAfter().run();
    },

    deleteRow() {
      this.editor.chain().focus().deleteRow().run();
    },

    deleteColumn() {
      this.editor.chain().focus().deleteColumn().run();
    },

    deleteTable() {
      this.editor.chain().focus().deleteTable().run();
    },

    addColumnBefore() {
      this.editor.chain().focus().addColumnBefore().run();
    },

    addColumnAfter() {
      this.editor.chain().focus().addColumnAfter().run();
    },

    toggleHeader() {
      if (this.editor.can().toggleHeaderRow()) {
        this.editor.chain().focus().toggleHeaderRow().run();
      }
    },

    // initialize the editor
    initializeEditor() {
      this.editor = new Editor({
        content: "",

        onUpdate: ({ editor }) => {
          this.content = editor.getHTML();
        },

        extensions: [
          StarterKit,
          Underline,

          Placeholder.configure({
            placeholder: "Type your note here...",
          }),

          Image.configure({
            inline: true,
            allowBase64: true,
            HTMLAttributes: {
              class: "tip-tap-images",
            },
          }),

          Table.configure({
            resizable: true,
          }),

          TableRow,
          TableCell,
          TableHeader,
        ],
      });
    },
  },

  computed: {
    isInTable() {
      return this.editor && this.editor.isActive("table");
    },
  },

  // watch value_to_edit if it has a value then set the content to edit
  watch: {
    value_to_edit: {
      immediate: true,
      handler(value) {
        if (value) {
          this.setContentToEdit(value);
        }
      },
    },

    content(value) {
      if (value && value !== "<p></p>" && !this.is_edit) {
        this.isTyping = true;
      } else {
        this.isTyping = false;
      }
    },
  },

  mounted() {
    this.initializeEditor();

    this.$refs.editorContent.$el.addEventListener("click", this.onImageClick);
  },

  beforeDestroy() {
    this.editor.destroy();

    this.$refs.editorContent.$el.removeEventListener(
      "click",
      this.onImageClick
    );
  },
};
</script>

<style lang="scss">
.tiptop-editor-toolbar {
  display: flex;
  flex-wrap: wrap;
  padding: 8px;
  background: #222;
  border-radius: 8px;
  border: 1px solid #333;

  button {
    margin: 0 5px 5px 0;
    border: none;
    background: none;
    color: #fff;
    cursor: pointer;
    transition: color 0.2s;

    &:hover {
      color: teal;
    }

    &.is-active {
      color: teal;
    }
  }
}

.ProseMirror {
  min-height: 200px;

  p {
    margin: 0 0 5px 0;
    font-weight: 200;
  }
}

.ProseMirror:focus {
  outline: none;
}

/* Placeholder (at the top) */
.tiptap p.is-editor-empty:first-child::before {
  content: attr(data-placeholder);
  float: left;
  color: #adb5bd;
  pointer-events: none;
  height: 0;
}

.tip-tap-images {
  cursor: pointer;
  max-width: 100%;
  height: auto;
}

// table
.tiptap {
  table {
    border-collapse: collapse;
    table-layout: fixed;
    width: 100%;
    margin: 0;
    overflow: hidden;

    td,
    th {
      min-width: 1em;
      border: 2px solid #333;
      padding: 3px 5px;
      vertical-align: top;
      box-sizing: border-box;
      position: relative;

      > * {
        margin-bottom: 0;
      }
    }

    th {
      font-weight: bold;
      text-align: left;
      background-color: #333;
    }

    .selectedCell:after {
      z-index: 2;
      position: absolute;
      content: "";
      left: 0;
      right: 0;
      top: 0;
      bottom: 0;
      background: rgba(200, 200, 255, 0.4);
      pointer-events: none;
    }

    .column-resize-handle {
      position: absolute;
      right: -2px;
      top: 0;
      bottom: -2px;
      width: 4px;
      background-color: #adf;
      pointer-events: none;
    }

    p {
      margin: 0;
    }
  }
}

.tableWrapper {
  padding: 1rem 0;
  overflow-x: auto;
}

.resize-cursor {
  cursor: ew-resize;
  cursor: col-resize;
}
</style>
